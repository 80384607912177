<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Historia Transakcji</b> w tym miejscu znajdują się Twoje dokumenty
        handlowe począwszy od daty przystąpienia do Programu Lojalnościowego
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Historia transakcji'"
          v-if="state === 'loaded'"
        >
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-table
                responsive
                striped
                hover
                :items="transactionHistory.items"
                :fields="transactionHistory.fields"
                :tbody-tr-class="rowClass"
                @row-clicked="rowClickHandler"
                :filter="filter"
              >
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>

    <template>
      <!-- details modal  -->
      <b-modal
        size="xl"
        scrollable
        ref="transactionHistoryDetailModal"
        :title="modalItems.items.documentFullNo"
      >
        <div class="d-block text-center"></div>
        <b-table
          responsive
          striped
          bordered
          hover
          :fields="modalItems.fields"
          :items="modalItems.items.positions"
        >
        </b-table>
      </b-modal>
      <!-- end of details modal -->
    </template>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      state: "loading",
      filter: null,
      transactionHistory: {
        fields: [
          {
            key: "documentFullNo",
            label: "Nr Dokumentu",
            sortable: true
          },
          {
            key: "sellDate",
            label: "Data",
            sortable: true
          },
          {
            key: "dueDate",
            label: "Termin",
            sortable: true
          },
          {
            key: "payment",
            label: "Wartość",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          },
          {
            key: "paid",
            label: "Zapłacono",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          },
          {
            key: "paymentLeft",
            label: "Pozostaje do zapłaty",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          },
          {
            key: "paymentStatus",
            label: "Status Płatności",
            sortable: true
          },
          {
            key: "pointsBySku",
            label: "Punkty",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          }
        ],
        items: []
      },
      modalItems: {
        fields: [
          {
            key: "skuCode",
            label: "Kod",
            sortable: true
          },
          {
            key: "skuName",
            label: "Nazwa",
            sortable: true
          },
          {
            key: "quantity",
            label: "Ilość",
            sortable: true
          },
          {
            key: "um",
            label: "jm",
            sortable: true
          },
          {
            key: "netAmount",
            label: "Netto",
            sortable: true,
            formatter: value => {
              return this.numberFormat(value);
            }
          },
          {
            key: "converter",
            label: "Przelicznik",
            sortable: true
          },
          {
            key: "points",
            label: "Punkty",
            sortable: true
          }
        ],
        items: []
      }
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getTransactionHistory() {
      ApiService.get("/transaction-history").then(response => {
        this.transactionHistory.items = response.data;
        this.state = "loaded";
      });
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.paymentStatus === "PO_TERMINIE") return "table-danger";
    },

    rowClickHandler(record) {
      this.$refs.transactionHistoryDetailModal.show();
      this.modalItems.items = record;
    },
    numberFormat(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Historia Transakcji" },
      { title: "" }
    ]);

    this.getTransactionHistory();
  }
};
</script>
